
/* Text in Buttons lässt sich nicht zentrieren
.buttons-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}
*/

/* bis 12.06.*/
/*
  @media (max-width: 768px) {

    .buttons-container {
      grid-template-columns: repeat(auto-fill, 40px)
    }
 
  }
 */

 .buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.button {
  flex: 0 0 calc(18% - 20px); /* Adjust the width as needed */
  margin: 2px; /* Adjust the margin as needed */
}
  
  .types {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .taskbuttons {
    flex: 0 0 calc(33.33% - 1rem);
    margin-bottom: 1rem;
  }
  
  kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    color: #333;
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
  }

  em {
    display: block;
    text-align: center;
    font-size: 24px;
    font-style: normal;
  }